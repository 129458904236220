import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";

export default function Videos() {
  const [videosData, setVideosData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "youtubeVideos"]{
            title,
            slug,
            mainImage{
                asset->{
                  _id,
                  url
                }
            }
          }`
      )
      //   .then((res) => res.json())
      // .then((data) => setPostsData(data))
      .then((data) => setVideosData(data)) // shorthand of above line
      .catch(console.error);
  }, []);

  console.log(videosData);

  return (
    <div className="bg-green-100 min-h-screen p-12">
      <div className="container mx-auto">
        <h2 className="text-5xl flex justify-center cursive">YouTube Videos</h2>
        <h3 className="text-lg text-gray-600 flex justify-center mb-12">
          Welcome to my YouTube page!
        </h3>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {videosData &&
            videosData.map((video, index) => (
              <Link
                to={"/youtube/" + video.slug.current}
                key={video.slug.current}
              >
                <span
                  className="block h-64 relative rounded shadow leading-snug bg-white border-l-8 border-green-400"
                  key={index}
                >
                  {video.mainImage && (
                    <img
                      className="w-full h-full rounded-r object-cover absolute"
                      src={video.mainImage.asset.url}
                      alt=""
                    />
                  )}
                  <span className="block relative h-full flex justify-end items-start pr-4 pt-4">
                    <h2 className="text-gray-800 text-lg font-bold px-3 py-4 bg-red-700 text-red-100 bg-opacity-75 rounded">
                      {video.title}
                    </h2>
                  </span>
                </span>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
}
