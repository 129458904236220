import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./components/Home.js";
import Posts from "./components/Posts.js";
import About from "./components/About.js";
import OnePost from "./components/OnePost.js";
import NavBar from "./components/NavBar.js";
import YouTubeVideos from "./components/YouTubeVideos.js";
import YouTube from "./components/YouTube.js";

function App() {
  return (
    <BrowserRouter>
      <NavBar />

      <Switch>
        <Route component={Home} path="/" exact />
        <Route component={OnePost} path="/posts/:slug" />
        <Route component={Posts} path="/posts" />
        <Route component={YouTube} path="/youtube/:slug" />
        <Route component={YouTubeVideos} path="/youtube" />

        <Route component={About} path="/about" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
